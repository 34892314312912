.iconContainer {
    display: flex;
    align-items: center;
}

.iconButton {
    display: flex;
    align-items: center;
    padding: 8px;
}

.icon {
    font-size: 28px;
    color: #cd0f3a;
}

.buttonCount {
    margin-left: 4px;
    font-size: 14px;
    color: #cd0f3a;
    display: flex;
    align-items: center;
    height: 28px;
}
