body {
    background-color: #141414;
}

body {
    background-color: #141414;

    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "wdth" 100;
}


li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: black;
}
