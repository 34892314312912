.CategoryPicker {}

.CategoryButton {
    margin: 4px !important;
    padding: 5px !important;
    background-color: #5efb52 !important;
    font-weight: bold !important;
    line-height: 1 !important;
    text-transform: none !important;
}

.Selected {
    color: #5efb52 !important;
    background-color: #1e1e1e !important;
    border: 1px solid #5efb52 !important;
}
