.iconContainer {
    display: flex;
    align-items: center;
}

.iconButton {
    display: flex;
    align-items: center;
    padding: 8px;
}

.icon {
    font-size: 28px;
    color: white;
}
