/* ReportPopup.module.css */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupInner {
    background-color: #2e2e2e; /* Dark background to match your theme */
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white; /* Ensure text is readable */
    position: relative;
}

form {
    display: flex;
    flex-direction: column;
}

form div {
    margin-bottom: 15px;
}

form label {
    margin-bottom: 5px;
    font-weight: bold;
}

form select,
form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #1e1e1e; /* Darker background for inputs */
    color: white; /* White text for readability */
}

form button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%; /* Make button full width */
    background-color: #5efb52; /* Color for submit button */
    color: black;
    font-weight: bold;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: white;
    cursor: pointer;
}
