.RecentSearch {
    cursor:pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
    max-width: 100%;
}

.RecentSearchCard {
    width: 100%;
}

.recentSearchContent {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    width:50%;

}
.searchCard:hover {
    background-color: #333;
}