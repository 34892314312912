.TagButton {
    margin: 4px !important;
    padding: 5px !important;
    background-color: #5BFCE4 !important;
    font-weight: bold !important;
    line-height: 1 !important;
    text-transform: none !important;
}

.TagButton::before {
    content: "#";
}