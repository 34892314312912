/* NewPostButton.module.css */

.mainButtonGradiant {
    position: relative;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer; /* Add cursor pointer */
    background: linear-gradient(45deg, #FFFFFF 0%, #FFFFFF 25%, #eafd5e 25%, #eafd5e 35%, #5bfce4 35%, #5bfce4 45%, #5efb52 45%, #5efb52 57.55%, #d002df 55%, #d002df 65%, #cd0f3a 65%, #cd0f3a 75%, #2309b8 75%, #2309b8 100%);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for hover effect */
}

.mainButtonGradiant:hover {
    transform: scale(1.1); /* Slightly enlarge the button */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5); /* Add a shadow on hover */
}

.blurBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(2px);
    z-index: 1;
    pointer-events: none; /* Ensure the blur does not interfere with clicks */
}

.mainButtonBorder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-width: 3px;
    border-color: #262629;
    border-style: solid;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: transparent;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainButtonIcon {
    font-size: 48px;
    color: #000; /* Change the color to black */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 3;
    position: relative; /* Ensure it stays above the blur */
}
