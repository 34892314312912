.root {
    border-radius: 25px;
    border: 1px solid #000;
    overflow: hidden;
    display: flex;
    height: 40px;
    width: 200px;
}

.toggleButton {
    flex: 1;
    color: #000;
    background-color: #fff;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    padding: 0;
    margin: 0;
}

.selected {
    background-color: #000 !important;
    color: #fff !important;
}

.notSelected {
    background-color: #fff !important;
    color: #000 !important;
}
